import React, {Fragment, useEffect, useState} from 'react';

import { Card, CardContent, Grid, Typography } from '@mui/material';

import Carousel from 'react-material-ui-carousel';
import {convertFromRaw, Editor, EditorState} from 'draft-js';
import { useIntl} from 'gatsby-plugin-intl';
import {useDispatch, useSelector} from 'react-redux';
import {fetchAlerts} from 'state/opportunities/alertSlice';
import themeMuiV5 from 'components/common/ThemeMuiV5';


const WelcomeMessage = (props) => {

    const intl = useIntl();
    const selector = state => state.alert.value;
    const alerts = useSelector(selector);
    const dispatch = useDispatch();
    const [searched, setSearched] = useState(false);
    let nameFirst = props.user?.cvcUser?.nameFirst;

    useEffect(() => {
        async function fetchData() {
            try {
                if (!searched && alerts.length === 0) {
                    await dispatch(fetchAlerts());
                }
                setSearched(true);
            } catch (error) {
                console.log('Error in fetching news: ', error);
            }
        };

        fetchData();
    }, [intl, alerts, dispatch]);
    
    return (
            <Grid item xs={12}>
                {nameFirst && <Card 
                 sx={{backgroundColor: themeMuiV5.palette.background.paper}} 
                 >
                <CardContent
                    sx={{
                        textAlign: "center",
                        color: themeMuiV5.palette.primary.main
                    }}
                >
                        <Typography variant="h6"
                                    gutterBottom>{intl.formatMessage({id: "header.welcome"}) + " " + nameFirst + " " + props.user?.cvcUser?.nameLast}
                        </Typography>
                        {(alerts.length > 0 &&
                            <Carousel interval={5000} animation={"fade"} navButtonsAlwaysInvisible={true}
                                      indicators={true}>
                                {
                                    alerts.map((alert, i) => <Alert key={i} item={alert}/>)
                                }
                            </Carousel>)}
                    </CardContent>
                </Card>}
            </Grid>
    );
};

const Alert = (props) => {
    const intl = useIntl();
    const [editorState, setEditorState] = React.useState(null);
    useEffect(() => {
        if (props.item && props.item.contentMarkdown) {
            const contentState = convertFromRaw(JSON.parse(props.item.contentMarkdown));
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [props.item])

    return (
        <Fragment>
            <Typography noWrap>
                <span><label
                    style={{fontWeight: 'bold'}}> {new Date(props.item.publishedFrom).toLocaleDateString(intl.locale)} - </label><label>{props.item.title} </label></span>
            </Typography>
            {editorState ? <Editor editorState={editorState} readOnly={true}/> : null}
        </Fragment>
    )
}

export default WelcomeMessage;
