import React, {Fragment, useState} from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    CardActionArea
} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBriefcase,
    faCalendarAlt,
    faClock,
    faExclamationCircle,
    faGlobe,
    faMapMarkerAlt,
    faPlayCircle,
    faSignal
} from '@fortawesome/free-solid-svg-icons';

import {injectIntl, useIntl} from 'gatsby-plugin-intl';
import {navigate} from 'gatsby-link';
import {CATALOG_FIELDS, COURSE_TYPE} from 'utils/constants/courses-constants';
import {GROUP} from 'utils/constants/back-office-constants';
import courseService from 'services/api/courseService';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import makeStyles from "@mui/styles/makeStyles";
import CourseLogo from 'components/catalog/common/CourseLogo';
import CourseType from 'components/catalog/common/CourseType';

const CourseList = ({ title, courses, fields, loading, courseType }) => {

    const intl = useIntl();
    let additionnalCourse = (courseType == COURSE_TYPE.additional) ? true : false;
    return (
        <Fragment>
            <Grid item lg={8} md={8} sm={12} xs={12}
            >

                <Card
                    sx={{backgroundColor: themeMuiV5.palette.background.paper,}}
                >
                    <CardHeader
                        title={title}
                        sx={{
                            textAlign: 'center',
                            backgroundColor: themeMuiV5.palette.primary.main,
                            color: themeMuiV5.palette.primary.contrastText
                        }}
                    />
                    <CardContent
                        sx={{backgroundColor: themeMuiV5.palette.background.paper,}}
                    >
                        {loading ?
                            <CircularProgress/> 
                            :
                            (courses && courses.length > 0 ? courses.sort().map((course, i) =>
                                        (
                                            <Course key={i} course={course} fields={fields}
                                                    additionnalCourse={additionnalCourse}/>
                                        )
                                    ) :
                                    (<Typography
                                        align='center'>{intl.formatMessage({id: "common.label.no.result"})}</Typography>)
                            )
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    );
}

const Course = ({course, fields, additionnalCourse}) => {

    let useStyles = makeStyles((theme) => ({
        root: {
            marginBottom: 20
        },
        // img: {
        //     maxWidth: "80px !important",
        //     maxHeight: "40px !important",
        //     marginBottom: 10,
        // },
        desc: {
            color: theme.palette.primary.main
        },

        // logo: {
        //     float: "right",
        //     justifyContent: 'center',
        //     flexWrap: 'wrap',
        //     border: "none",
        //     color: "black"
        // },
        chipTitle: {
            float: "right",
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        chipTitleRevert: {
            float: "right",
            justifyContent: 'center',
            flexWrap: 'wrap',
            border: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main
        },
        chip: {
            float: "right",
            justifyContent: 'center',
            flexWrap: 'wrap',
            border: "none",
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main
        },
        caption: {
            marginRight: 16,
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
        },
        link: {
            textDecoration: "none",
            color: theme.palette.primary.main,
        },
        clickable: {
            cursor: "pointer"
        },
        sectionInfo: {
            marginLeft: theme.spacing(2),
        },
        sectionDescription: {
            marginRight: theme.spacing(5),
        }
    }))

    const intl = useIntl();
    const classes = useStyles();


    return (
        <Card
            sx={{marginBottom: 2}}
        > 
            <CardActionArea
            onClick={() => {
                navigate("/" + intl.locale + "/courses/" + course.id)
            }}
            >
                <CardContent>
                    <Grid container>
                        <Grid item xl={7} md={7} sm={12} sx={{ overflowX: 'auto' }}>
                            {(additionnalCourse == false) ? (
                                <Typography variant="subtitle1" component="h2">
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        color={themeMuiV5.palette.primary.main}
                                    />

                                    <Typography component="span"
                                                sx={{marginLeft: themeMuiV5.spacing(2),}}>
                                        {(course.multiJoinCode && fields.includes(CATALOG_FIELDS.multiCode)) ? course.multiJoinCode : ""}
                                        {(!!!course.multiJoinCode && course.code && fields.includes(CATALOG_FIELDS.courseCode)) ?
                                            course.universityName + ' - ' + course.code : ""}
                                    </Typography>
                                </Typography>) : ""
                            }
                            <Typography variant="h6" component="h3">
                                {course.title}
                            </Typography>
                            <Typography component="div"
                                        sx={{marginRight: themeMuiV5.spacing(5),}}
                            >
                                <Typography
                                    variant="body1"
                                    component="p"
                                    align="justify"
                                    gutterBottom
                                >
                                    {course.shortDescription}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem/>
                        <Grid item xl={4} md={4} sm={12}>
                            <div className={classes.sectionInfo}>
                                
                                <CourseLogo course={course}/>
                                <CourseType courseType={course.type} courseSubType={course.subtype} isEngage={course.engageCourse} pageFields={fields}/>
                                {course.language ?
                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faGlobe}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}>
                                            {course.language &&
                                                intl.formatMessage({
                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                        GROUP.language,
                                                        course.language
                                                    ),
                                                })}
                                        </Typography>
                                    </Typography> : ''}
                                {course.period ?
                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faCalendarAlt}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}
                                        >
                                            {intl.formatMessage({
                                                id: courseService.getLabelKeyByGroupAndCode(
                                                    GROUP.period,
                                                    course.period
                                                ),
                                            })}
                                        </Typography>
                                    </Typography> : ''}
                                {course.firstSessionDate ?

                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faPlayCircle}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}
                                        >
                                            {intl.formatMessage({id: "course.first.session"})}
                                            : {new Date(course.firstSessionDate).toLocaleDateString(intl.locale)}
                                        </Typography>
                                    </Typography> : ''}

                                {course.nbSessions ?
                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}
                                        >
                                            {intl.formatMessage({id: "course.sessions.count"})}
                                            : {course.nbSessions}
                                        </Typography>
                                    </Typography> : ''}

                                {course.cycles && course.cycles.length > 0 ?
                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faSignal}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}
                                        >
                                            {course.cycles && course.cycles.length > 0 &&
                                                course.cycles.map((value, idx) => {
                                                    let returnMsg = intl.formatMessage({ id: courseService.getLabelKeyByGroupAndCode(GROUP.courseCycle, value.code) });
                                                    if (idx !== course.cycles.length - 1) {
                                                        returnMsg += ', ';
                                                    }
                                                    return returnMsg;
                                                })
                                            }
                                        </Typography>
                                    </Typography> : ''}

                                {course.registrationDeadline ?

                                    <Typography variant="body1" paragraph>
                                        <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                        <Typography component="span"
                                                    sx={{marginLeft: themeMuiV5.spacing(1),}}
                                        >
                                            {intl.formatMessage({id: "course.deadline.registration"})}
                                            : {new Date(course.registrationDeadline).toLocaleDateString(intl.locale)}
                                        </Typography>
                                    </Typography> : ''}

                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )

};

export default CourseList;
