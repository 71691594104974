import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Alert,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton, Snackbar,
    Typography
} from "@mui/material";
import themeMuiV5 from "components/common/ThemeMuiV5";
import { useIntl } from "gatsby-plugin-intl";
import React, { Fragment, useEffect, useState } from "react";
import { RhfTextField, RhfTextFieldMultiline } from 'components/backoffice/common/RhfFields';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import mailService from "../../../../services/api/mailService";
import { validateMailToSend } from "../../../../utils/mail-schema";
import { INPUT_MAX_LENGTH, MAILS } from "../../../../utils/constants/constants";


const Message = ({ open, handleClose, message, academic, photo }) => {
    const intl = useIntl();
    let mailDefaultValues = {
        subject: message ? message.subject : '',
        body: message ? message.message : '',
        files: []
    };
    const useFormHook = useForm(
        {
            resolver: yupResolver(validateMailToSend),
            defaultValues: mailDefaultValues,
            mode: "onChange",
        }
    );

    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [isEditPage, setIsEditPage] = useState(!!message?.mailId);
    const [fileLimit, setFileLimit] = useState(false);
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const [reload, setReload] = useState(false);

    const handleCapture = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);

    };
    const handleCancel = (e) => {
        useFormHook.reset();
        deleteFile()
        handleClose();

    };
    const handleUploadFiles = files => {
        const selected = [...selectedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (selected.findIndex((f) => f.name === file.name) === -1) {
                if (file.size < MAILS.MAX_CONTENT_SIZE) {
                    selected.push(file);

                } else {
                    handleError({ data: { errorCode: "message.file.size.error" } })
                }
                if (selected.length === MAILS.MAX_FILES_COUNT) setFileLimit(true)
                if (selected.length > MAILS.MAX_FILES_COUNT) {
                    setFileLimit(false)
                    limitExceeded = true
                    return true
                }
            }
        })
        if (!limitExceeded) setSelectedFiles(selected)
    }

    const deleteFile = (i) => {
        if (i >= 0) {
            selectedFiles.splice(i, 1);
            setSelectedFiles(selectedFiles);
        } else {
            setSelectedFiles([]);
        }
        setReload(true);
    };

    useEffect(() => {
        setReload(false);
    }, [reload]);

    const handleError = (error) => {
        let errLabel = "message.send.error";
        if (error && error.data && error.data.errorCode) {
            console.log("error : ", error);
            errLabel = error.data.errorCode;
        }
        setSubmitMessage(errLabel);
        setOpenError(true);
        setDisableSubmit(false);
    }
    const [submitMessage, setSubmitMessage] = React.useState("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(false);

    const mapMailFront2Back = (mail) => {
        mail.files = selectedFiles;
        mail.destinataire = academic.email
        mail.destId = academic.academicId
        return mail;
    }
    const onSubmit = mail => {
        setIsEditPage(true);
        setDisableSubmit(true);
        setOpenSuccess(false);
        setOpenError(false);
        mail = mapMailFront2Back(mail);

        mailService
            .sendMail(mail)
            .then((result) => {
                setSubmitMessage("message.send.success");
                setOpenSuccess(true);
                setDisableSubmit(false);
                setTimeout(function () {
                    handleClose();
                }, MAILS.SENT_MAIL_TIMEOUT);


            })
            .catch((error) => {
                handleError(error);
            });

    };


    const onError = (errors, e) => console.log(errors, e);
    return (
        <div>
            <Dialog
                open={open}
            >{openSuccess && (
                <Snackbar open={openSuccess} autoHideDuration={6000}
                    anchorOrigin={{ vertical, horizontal }}
                    key={"success_" + vertical + horizontal}
                    onClose={() => setOpenSuccess(false)}>
                    <Alert
                        severity="success"
                        onClose={() => setOpenSuccess(false)}
                        action={
                            <IconButton
                                aria-label="close success"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenSuccess(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                            </IconButton>
                        }
                    >
                        {intl.formatMessage({ id: submitMessage })}
                    </Alert>
                </Snackbar>
            )}
                {openError && (
                    <Snackbar open={openError}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical, horizontal }}
                        key={"error_ " + vertical + horizontal}
                        onClose={() => setOpenError(false)}

                    >
                        <Alert
                            severity="error"
                            onClose={() => setOpenError(false)}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenError(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </IconButton>
                            }
                        >
                            {intl.formatMessage({ id: submitMessage })}
                        </Alert>
                    </Snackbar>
                )}
                <form onSubmit={useFormHook.handleSubmit(onSubmit, onError)}>
                    <DialogContent>

                        <Grid container>
                            <Grid item xs={3}>
                                <Typography component="div">
                                    <Avatar alt={"photo " + academic?.lastName} src={photo}
                                        sx={{ width: 100, height: 100 }} />
                                </Typography>
                            </Grid>
                            {!isEditPage ?
                                <Grid item xs={9}>
                                    <Typography component="h3" mb={1} sx={{ fontWeight: 600 }}>
                                        {intl.formatMessage({ id: "message.send" })} {academic?.lastName} {academic?.firstName} ({academic?.universityName} - {academic?.universityUnitName})
                                    </Typography>
                                    <Typography component="p" sx={{ color: '#d32f2f' }}>
                                        {intl.formatMessage({ id: "message.warning" })}
                                    </Typography>
                                    <Typography component="p">
                                        {intl.formatMessage({ id: "message.contact" })}
                                    </Typography>
                                    <Typography component="p">
                                        {intl.formatMessage({ id: "message.copy" })}
                                        <a
                                            href={intl.formatMessage({ id: "message.code.conduct.link" })}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt={intl.formatMessage({ id: "message.code.conduct.text" })}
                                        >
                                            {intl.formatMessage({ id: "message.code.conduct.text" })}
                                        </a>
                                        {intl.formatMessage({ id: "message.copy.end" })}
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={9}>
                                    <Typography component="h3" mb={1} sx={{ fontWeight: 600 }}>
                                        {intl.formatMessage({ id: "message.sent" })} {academic?.lastName} {academic?.firstName} ({academic?.universityName} - {academic?.universityUnitName})
                                    </Typography>
                                </Grid>}

                        </Grid>



                        <RhfTextField
                            label="message.subject"
                            name="subject"
                            required={true}
                            disabled={isEditPage}
                            fullWidth
                            control={useFormHook.control}
                            error={useFormHook.formState.errors ? useFormHook.formState.errors.subject : null}
                            maxLength={INPUT_MAX_LENGTH.MAIL_SUBJECT} />

                        <RhfTextFieldMultiline
                            label="message.body"
                            name="body"
                            required={true}
                            disabled={isEditPage}
                            fullWidth
                            control={useFormHook.control}
                            error={useFormHook.formState.errors ? useFormHook.formState.errors.body : null} />

                        {!isEditPage && selectedFiles.length < MAILS.MAX_FILES_COUNT &&
                            <Button variant="outlined" component="label" sx={{ marginRight: themeMuiV5.spacing(1), marginTop: themeMuiV5.spacing(1) }}>
                                <Typography component="p">
                                    <FontAwesomeIcon icon={faUpload} />
                                    {" "}
                                    {intl.formatMessage({ id: "message.upload.file" })}
                                    <input hidden accept=".pdf" multiple type="file" onChange={handleCapture} />
                                </Typography>
                            </Button>}
                        {selectedFiles && selectedFiles.length > 0 ? selectedFiles.map((selectedFile, i) =>
                        (<Fragment>
                            <Typography component="span" key={`file${i}`} sx={{ marginRight: themeMuiV5.spacing(1), marginTop: themeMuiV5.spacing(1) }}>
                                <input hidden accept=".pdf" type="file"
                                // id={`file${i}`}
                                />
                                {selectedFile.name}
                            </Typography>
                            <IconButton
                                aria-label="deleteImage"
                                color="primary"
                                size="small"
                                key={`deleteFile-${i}`}
                                onClick={() => deleteFile(i)}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                        </Fragment>)
                        ) : ""
                        }

                    </DialogContent>
                    {
                        !!isEditPage ?
                            <DialogActions>
                                <Button onClick={handleCancel}>{intl.formatMessage({ id: "message.btn.close" })}</Button>
                            </DialogActions>
                            :
                            <DialogActions>
                                <Button onClick={handleCancel}>{intl.formatMessage({ id: "message.btn.cancel" })}</Button>
                                <Button type="submit"
                                    disabled={disableSubmit}>{intl.formatMessage({ id: "message.btn.send" })}</Button>
                            </DialogActions>
                    }

                </form>
            </Dialog>
        </div>
    )
        ;
};

export {
    Message
};
