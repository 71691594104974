import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import {useForm} from 'react-hook-form';
import {useIntl} from 'gatsby-plugin-intl';

import { CATALOG_COLUMNS_BY_TYPE, CATALOG_CRITERIA_BY_TYPE, CATALOG_FIELDS_BY_TYPE, COURSE_TYPE } from 'utils/constants/courses-constants';
import {GROUP} from 'utils/constants/back-office-constants';
import {getUser} from 'services/api/requests';
import courseService from 'services/api/courseService';
import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import WelcomeMessage from 'components/common/WelcomeMessage';
import SearchEngine from 'components/catalog/courses/SearchEngine';
import CourseList from 'components/catalog/courses/CourseList.js';
import * as searchStyles from 'styles/search-page.module.scss';

const CourseListPage = ({ courseType }) => {
    const intl = useIntl();
    let columns = [
        {
            field: "universityAcronym",
            headerName: intl.formatMessage({id: "course.university"}),
            width: 130,
        },

        {
            field: "code",
            headerName: intl.formatMessage({id: "course.code"}),
            width: 200,
            renderCell: (params => {
                const typeCode = params.value;
                let multiJoinCode = params.api.getRow(params.id)['multiJoinCode'];
                return multiJoinCode? multiJoinCode : typeCode ;
            })
        },
        {
            field: "title",
            headerName: intl.formatMessage({id: "course.title"}),
            width: 200,
        },
        {
            field: "type",
            headerName: intl.formatMessage({id: "course.type"}),
            width: 160,
            renderCell: (params) => {
                const typeCode = params.value;
                return intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.courseType,
                        typeCode
                    ),
                });
            },
        },
        {
            field: "subtype",
            headerName: intl.formatMessage({id: "course.subtype"}),
            width: 160,
            renderCell: (params) => {
                const typeCode = params.value;
                return typeCode && intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.courseSubtype,
                        typeCode
                    ),
                });
            },
        },
        {
            field: "teacher",
            headerName: intl.formatMessage({id: "course.teachers"}),
            width: 160
        },
        {
            field: "cycles",
            headerName: intl.formatMessage({id: "course.cycle"}),
            width: 130,
            renderCell: (params) => {
                const cycles = params.value;
                return cycles
                    .map((cycle) =>
                        intl.formatMessage({
                            id: courseService.getLabelKeyByGroupAndCode(
                                GROUP.courseCycle,
                                cycle.code
                            ),
                        })
                    )
                    .join();
            },
        },
        {
            field: "period",
            headerName: intl.formatMessage({id: "course.period"}),
            width: 170,
        },
        {
            field: "studentCount",
            headerName: intl.formatMessage({id: "course.student.count"}),
            width: 170,
        },
    ];

    let sortModelCourse = [
        {
            field: 'semester',
            sort: 'desc',
        },
        {
            field: 'FIRST_SESSION_DATE',
            sort: 'asc',
        }
    ];

    let sortModelAdditional = [
        {
            field: 'course_id',
            sort: 'desc',
        },
        {
            field: 'title',
            sort: 'asc',
        }
    ];
   
    const pageSize = 50;
    const [rows, setRows] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false); 
    const defaultValues = useMemo(() => {
            return {
                textFilter: '',
                courseType: '',
                courseSubtype: '',
                courseUniversity: '',
                courseCycle: '',
                coursePeriod: '',
                courseLanguage: '',
                topics: [],
                engageCourse:false,
            }
        },[]
    );
    const methods = useForm({defaultValues: defaultValues, mode: "onChange"});
    const searchCriteria = useRef({});

    const updateUrl = () => {
        const searchCriterias = searchCriteria.current;
        removeEmptyCriterias(searchCriterias);
        const urlSearchParams = new URLSearchParams(searchCriterias);
        const additionnalQueryString = courseType ? ('/'+courseType) : '';
        const searchQueryString = urlSearchParams && urlSearchParams.size > 0 ? ('?'+urlSearchParams.toString()):'';
        const urlToGo = "/"+intl.locale+'/catalog'+additionnalQueryString+searchQueryString
        window.location.replace(urlToGo);
    }

    const removeEmptyCriterias = (searchCriterias) => {
        for (const [key, value] of Object.entries(searchCriterias)) {
            if(value == null || value == undefined || value == '' || value.length == 0){
                delete searchCriterias[key];
            }
        }
    }

    const resetForm = () => {
        methods.reset(defaultValues);
        searchCriteria.current = methods.getValues();
        updateUrl();        
    };

    const submitForm = (formData) => {
        columns = columns.filter(column => CATALOG_COLUMNS_BY_TYPE(courseType).includes(column.field))
        searchCriteria.current = formData;
        updateUrl();
    };

    const loadServerRows = useCallback(async (page) => {
        let sortModel;
        setLoading(true);
        if (courseType) {
            searchCriteria.current.courseType = courseType
            sortModel= sortModelAdditional;
        }else{
            sortModel= sortModelCourse;
        }
        const data = await courseService.searchCatalogByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel
        );
        const {content} = {...data.data}
        setRows(!!content ? content : []);
        setLoading(false);
    }, []);

    const getSearchParamsFromUrl = () => {
        const urlParams = Object.fromEntries(new URLSearchParams(location?.search));
        if(urlParams['engageCourse']){
            urlParams['engageCourse'] = urlParams['engageCourse'] === 'true';
        }
        if(urlParams['topics']){
            urlParams['topics'] = urlParams['topics'].split(',');
        }
        return urlParams;        
    }

    useEffect(() => {
        const urlParams = getSearchParamsFromUrl();   
        searchCriteria.current=urlParams;
        methods.reset(urlParams);
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                let user = await getUser();
                setUser(user.data)
            } catch (error) {
                console.log('error in fecthing user data\n', error);
            }
        };
        loadServerRows(0);
        setRows([]);
        fetchData();
    }, [loadServerRows]);

    return (
        <Layout>
            <Seo
                title={intl.formatMessage({id: "course.catalog"})}
                lang={intl.locale}
            />

            <WelcomeMessage user={user}/>
            
            {courseType ?
                <Grid item lg={12} md={12} sm={12} xs={12} style={{margin: "10px"}} >
                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "course.opportunity.desc" })}
                    </Typography>
                </Grid>
            :   <Grid item lg={12} md={12} sm={12} xs={12} style={{margin: "10px"}}>
                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "course.desc" })}
                    </Typography>
                </Grid>
            }

            <div id="main" className={searchStyles.main}>                
                <div id="searchEngine" className={searchStyles.searchEngine}>
                    <SearchEngine
                        resetForm={resetForm}
                        submitForm={submitForm}
                        methods={methods}
                        courseType={courseType}
                        filters={CATALOG_CRITERIA_BY_TYPE(courseType)}
                    />
                </div>   
                <div id="resultList" className={searchStyles.resultList}>
                    <CourseList
                        title={courseType ?  intl.formatMessage({id: "course.list."+courseType.toString().toLowerCase()}) : intl.formatMessage({id: "course.list"})}
                        courses={rows}
                        loading={loading}
                        fields={CATALOG_FIELDS_BY_TYPE(courseType)}
                        courseType={courseType}
                    />
                </div>                     
            </div>
   
        </Layout>
    );
};

export default CourseListPage;


