import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';

import { Autocomplete, TextField, Chip } from "@mui/material";
import {Controller } from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl";

import {fetchListOfValues, GROUP_STATE} from "state/common/listOfValueSlice";

const RhfAutocompleteListOfValuesSelect = ({
    group,
    name,
    label,
    defaultValue,
    control,
    id,
    setValue,
    className,
    ...props
}) => {

    const intl = useIntl();

    const selector = GROUP_STATE[group].state;
    const valuesList = useSelector(selector);
    const dispatch = useDispatch()

    const fixedOptions = (defaultValue) ? [defaultValue] : [];
    const [value, setValueEntry] = useState([...fixedOptions]);
    
    const chipStandard = (option, index, getTagProps) => (
        (option) ? <Chip key={option?.value} label={option?.label} {...getTagProps({ index })} /> : ""
    );


    function methodEqual(option, value) {
        const isEqual = option?.value === value?.value;
        return isEqual;
    }

    function methodOption(option) {
        return option?.label;
    }


    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            try {
                if (mounted && valuesList.length === 0) {
                    dispatch(fetchListOfValues(group));
                }
            } catch (error) {
                console.log(`error fetching list of values ${group} :`, error);
            }
        }
        fetchData();
        return () => { mounted = false };
    }, []);


    useEffect(() => {
        if (value && value?.length >= 0) {
            setValue(name, value);
        }
    }, [value]);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value } }) => (
                <Autocomplete
                    {...props}
                    multiple
                    autoComplete
                    id={id}
                    key={value}
                    value={value}
                    filterSelectedOptions
                    options={valuesList}
                    getOptionLabel={methodOption}
                    renderTags={(tagValue, getTagProps) =>
                        {
                            const tags = tagValue.map((option, index) => {
                                if(option.label){
                                    return chipStandard(option, index, getTagProps);
                                } else {
                                    let selectedValue = valuesList.find(item  => item.value === option.value);
                                    return chipStandard(selectedValue, index, getTagProps);
                                }                                
                            });                            
                            return tags;
                        }
                    }
                    renderInput={(params) => (
                        <TextField {...params}
                            label={intl.formatMessage({ id: label })}
                            placeholder={intl.formatMessage({ id: label })}
                            variant="standard"
                            className={className}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => methodEqual(option, value)}
                    onChange={(event, newValue) => {
                        setValueEntry([
                            ...newValue,
                        ]);
                    }}
                />
            )}
        />
    );
};

export default RhfAutocompleteListOfValuesSelect;